// COLORS
$color-principal: #00B398;
$color-secundary: #51534A;
$color-warning: #FC4C02;
$color-sucess: #1CC200;
$color-text-login: #455a64cd;


$color-btn-disabled: #32C2AC;
$color-btn-hover: #009779;

$text-btn-active: white;
$text-color-title: rgba(0, 0, 0, 1);
$text-color-label: rgba(0, 0, 0, 1);
$text-color: #FFFFFF;
$text-color-title-menu:  #51534A;
$text-color-item-list: rgba(81, 83, 74, 0.7);
$text-card :#51534a99;
$text-title-card: #1cd1b6;
$text-color-black: rgba(0,0,0,1);



$background-inputs: #FFFF;
$shadow-inputs: rgba(0, 0, 0, 0.25);
$border-inputs: rgba(0, 0, 0, 0.25);

// ? Header
$background-header: #FFFF;
$shadow-header: rgba(81, 83, 74, 0.25);
$background-select: rgba(81, 83, 74, 0.8);
$color-effects-menu: #00B398;


// ? TABLES
$shadow-container:0px 0px 4px rgba(81, 83, 74, 0.5);



// !Common
$background-base: #FFFF;
$shadow-base: rgba(0, 0, 0, 0.30);
$color-icon-menu: #51534a7a;
$text-color-base-info: #000000;
$hover-card-base:#00B398;
$text-base: white;
$border-color-base: rgba(81, 83, 74, 0.3);